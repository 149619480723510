<template>
    <div>
      <h1>Home Terminal</h1>
      <p>Welcome to the Home Terminal!</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HomeTerminal',
  };
  </script>
  
  <style scoped>
  
  </style>
  