<template>
  <div class="p-4">
    <!-- Step 1: Add container around the categories -->
    <div class="bg-white p-6 rounded shadow-md">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-xl font-bold">Categories</h2>
        <button @click="openAddModal" class="py-2 px-4 bg-gray-700 text-white rounded-md">
          + Add a Category
        </button>
      </div>
      <!-- Step 2: Change the grid layout for even distribution -->
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
        <div v-for="category in categories" :key="category.id" class="relative category-box bg-gray-100 p-4 rounded shadow-md text-center">
          <button class="absolute top-2 right-2 bg-white text-gray-700 px-2 py-1 rounded shadow" @click="openEditModal(category)">Edit</button>
          <div class="icon-container flex justify-center items-center h-16">
            <img :src="getCategoryIconUrl(category.icon)" alt="category icon" class="w-12 h-12">
          </div>
          <p class="mt-2 text-sm font-semibold">{{ category.name }}</p>
        </div>
      </div>
      <div class="footer mt-4">
        <div class="product-count">Total {{ totalCategories }} categories</div>
      </div>
    </div>
    <Modal v-if="showModal" title="Edit Category" @close="closeModal">
      <CategoryForm :categoryId="selectedCategoryId" @cancel="closeModal" @close-success="fetchCategories"></CategoryForm>
    </Modal>
  </div>
</template>


<script>
import Modal from './ModalWindow.vue';
import CategoryForm from './CategoryForm.vue';
import axios from '../api/axios';

export default {
  components: {
    Modal,
    CategoryForm
  },
  data() {
    return {
      categories: [],
      showModal: false,
      selectedCategoryId: null
    };
  },
  computed: {
    totalCategories() {
      return this.categories.length;
    },
  },
  methods: {
    openAddModal() {
      this.selectedCategoryId = null;
      this.showModal = true;
    },
    openEditModal(category) {
      this.selectedCategoryId = category.id;
      this.showModal = true;
      console.log('Edit category:', category.id);
    },
    closeModal() {
      this.showModal = false;
      this.selectedCategoryId = null;
    },
    getCategoryIconUrl(iconPath) {
      const apiBaseUrl = process.env.VUE_APP_API_BASE_URL || 'https://api.testing-9.cashier.lk/public/api/';
      return `${apiBaseUrl}${iconPath}`;
    },
    async fetchCategories() {
      try {
        const response = await axios.get('/v1/web/categories');
        if (response.data && response.data.data) {
          this.categories = response.data.data.data;
        }
      } catch (error) {
        console.error('Error fetching categories:', error.response ? error.response.data : error.message);
      }
    },
  },
  mounted() {
    this.fetchCategories();
  },
};
</script>


<style scoped>
.category-box {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.icon-container {
  height: 50px;
}

button {
  font-size: 0.75rem;
}

.footer {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  border-top: 1px solid #ccc;
  background: #f9f9f9;
  margin-top: 1rem;
}

.product-count {
  font-weight: bold;
}
</style>

