// src/api/axios.js
import axios from 'axios';
import router from '../router';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://api.testing-9.cashier.lk/public/api', // Set base URL to /api
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    const language = localStorage.getItem('language'); // Get language from localStorage
    if (language) {
      config.headers['lang'] = language;
    }
    return config;
  },
  error => Promise.reject(error)
);

instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      router.push('/login');
    }
    return Promise.reject(error);
  }
);

export const getBuyers = async (page = 1, search = '') => {
  try {
    const response = await instance.get('/v1/web/buyers', {
      params: {
        page,
        search,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching buyers:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const getSellers = async (page = 1, search = '') => {
  try {
    const response = await instance.get('/v1/web/sellers', {
      params: {
        page,
        search,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching sellers:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// Fetch admins
export const getAdmins = async (page, search) => {
  return await axios.get('/v1/web/admins', {
    params: { page, search },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}` // Ensure token is included
    }
  });
};

// Add an admin
export const addAdmin = async (adminData) => {
  return await axios.post('/v1/web/admins', adminData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}` // Ensure token is included
    }
  });
};


export default instance;
