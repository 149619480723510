<template>
    <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" @click.self="closeModal">
      <div class="bg-white rounded-lg overflow-hidden shadow-xl max-w-lg w-full">
        <div class="p-4 border-b">
          <h3 class="text-xl font-semibold">{{ title }}</h3>
          <button class="absolute top-4 right-4 text-gray-700" @click="closeModal">✕</button>
        </div>
        <div class="p-4">
          <slot></slot>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      title: {
        type: String,
        default: 'Modal Title'
      }
    },
    methods: {
      closeModal() {
        this.$emit('close');
      }
    }
  };
  </script>
  