<template>
  <div class="p-4 space-y-4">
    <div class="bg-gray-100 p-6 rounded shadow">
      <h2 class="text-xl font-bold mb-2">Lorem ipsum</h2>
      <ul class="list-disc pl-5">
        <li v-for="item in items" :key="item" class="py-1">
          Lorem ipsum dolor : <span class="text-gray-700">Lorem ipsum dolor</span>
        </li>
      </ul>
    </div>
    <div class="bg-gray-100 p-6 rounded shadow">
      <h2 class="text-xl font-bold mb-2">Lorem ipsum</h2>
      <ul class="list-disc pl-5">
        <li v-for="item in items" :key="item" class="py-1">
          Lorem ipsum dolor : <span class="text-gray-700">Lorem ipsum dolor</span>
        </li>
      </ul>
    </div>
    <div class="bg-gray-100 p-6 rounded shadow">
      <h2 class="text-xl font-bold mb-2">Lorem ipsum</h2>
      <ul class="list-disc pl-5">
        <li v-for="item in items" :key="item" class="py-1">
          Lorem ipsum dolor : <span class="text-gray-700">Lorem ipsum dolor</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: Array(8).fill('') // Creates an array with 8 empty items
    };
  }
};
</script>

<style scoped>
/* Additional custom styles if needed */
</style>
