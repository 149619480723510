<template>
  <div class="buyers-terminal">
    <div class="ag-theme-alpine" style="height: 100%; width: 100%;">
      <div class="ag-grid-header">
        <div class="header-cell">Buyer</div>
        <div class="header-cell">Inquired Count</div>
        <div class="header-cell">Saved Businesses</div>
        <div class="header-cell">Account Type</div>
        <div class="header-cell"></div>
      </div>
      <div class="ag-grid-body">
        <div class="grid-row" v-for="(row, index) in rowData" :key="index">
          <div class="cell buyer-cell">
            <img :src="row.profile_image || defaultImage" class="profile-image-circle" />
            <div class="buyer-info">
              <div class="buyer-name">{{ row.name }}</div>
              <div class="joined-date">Joined: {{ row.created_at || 'N/A' }}</div>
            </div>
          </div>
          <div class="cell">{{ row.inquired_count }}</div>
          <div class="cell">{{ row.saved_businesses }}</div>
          <div class="cell">{{ row.account_type }}</div>
          <div class="cell">
            <button class="edit-button" @click="selectBuyer(row)">Edit</button>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="product-count">
        Total {{ totalProducts }} Buyers
      </div>
      <div class="pagination">
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="prevPage" :disabled="currentPage === 1">&lt;</button>
        <span v-for="page in pages" :key="page" @click="goToPage(page)" :class="{ active: page === currentPage }">{{ page }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">&gt;</button>
      </div>
    </div>
    <Modal v-if="selectedBuyer" @close="clearSelection">
      <ProfileView
        v-if="!showDetailedView"
        :buyer="selectedBuyer"
        @close="clearSelection"
        @detailed-view="showDetailedView = true"
      />
      <ProfileModalView
        v-if="showDetailedView"
        :buyer="selectedBuyer"
        @back="showDetailedView = false"
      />
    </Modal>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { getBuyers } from '../api/axios';
import ProfileView from './ProfileView.vue';
import ProfileModalView from './ProfileModalView.vue';
import Modal from './ModalWindow.vue'; // Assuming you have a Modal component

export default {
  name: 'BuyersTerminal',

  components: {
    ProfileView,
    ProfileModalView,
    Modal,
  },

  setup() {
    const rowData = ref([]);
    const defaultImage = '/assets/images/p.jpeg';
    const currentPage = ref(1);
    const totalProducts = ref(0);
    const productsPerPage = ref(10);
    const searchQuery = ref('');
    const selectedBuyer = ref(null);
    const showDetailedView = ref(false);

    const totalPages = computed(() => Math.ceil(totalProducts.value / productsPerPage.value));
    const pages = computed(() => {
      const range = [];
      for (let i = 1; i <= totalPages.value; i++) {
        range.push(i);
      }
      return range;
    });

    const fetchBuyers = async () => {
      try {
        const response = await getBuyers(currentPage.value, searchQuery.value);
        rowData.value = response.data.data;
        totalProducts.value = response.data.total;
      } catch (error) {
        console.error('Failed to fetch buyers:', error);
      }
    };

    onMounted(() => {
      fetchBuyers();
    });

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
        fetchBuyers();
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
        fetchBuyers();
      }
    };

    const goToPage = (page) => {
      currentPage.value = page;
      fetchBuyers();
    };

    const selectBuyer = (buyer) => {
      selectedBuyer.value = buyer;
    };

    const clearSelection = () => {
      selectedBuyer.value = null;
      showDetailedView.value = false;
    };

    return {
      rowData,
      defaultImage,
      currentPage,
      totalProducts,
      productsPerPage,
      totalPages,
      pages,
      prevPage,
      nextPage,
      goToPage,
      selectBuyer,
      selectedBuyer,
      showDetailedView,
      clearSelection,
    };
  },
};
</script>

<style scoped>
.buyers-terminal {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.ag-theme-alpine {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ag-grid-header, .ag-grid-body {
  display: flex;
  flex-direction: column;
}

.ag-grid-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  background: #f9f9f9;
  border-bottom: 1px solid #ccc;
}

.header-cell, .cell {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  text-align: center;
}

.grid-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  background-color: white;
}

.buyer-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-image-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.buyer-info {
  text-align: left;
}

.buyer-name {
  font-weight: bold;
}

.joined-date {
  font-size: 12px;
  color: #888;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  border-top: 1px solid #ccc;
  background: #f9f9f9;
}

.product-count {
  font-weight: bold;
}

.pagination {
  display: flex;
  align-items: center;
}

.pagination span,
.pagination button {
  margin: 0 5px;
  cursor: pointer;
}

.pagination span.active {
  font-weight: bold;
}

.pagination button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.edit-button {
  background-color: white;
  border: none;
  color: rgb(0, 0, 0);
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 2px 1px;
  cursor: pointer;
  border-radius: 4px;
}
</style>
