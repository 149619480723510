<template>
    <div class="settings-terminal">
      <div class="ag-theme-alpine" style="height: 100%; width: 100%;">
        <div class="ag-grid-header">
          <div class="header-cell">Admin</div>
          <div class="header-cell">Role</div>
          <div class="header-cell">Actions</div>
        </div>
        <div class="ag-grid-body">
          <div class="grid-row" v-for="(row, index) in rowData" :key="index">
            <div class="cell admin-cell">
              <img :src="row.profile_image || defaultImage" class="profile-image-circle" />
              <div class="admin-info">
                <div class="admin-name">{{ row.name }}</div>
                <div class="joined-date">Since: {{ row.created_at || 'N/A' }}</div>
              </div>
            </div>
            <div class="cell">
              <span :class="['role', { 'super-admin': row.role === 'Super Admin' }]">{{ row.role }}</span>
            </div>
            <div class="cell">
              <button class="edit-button" @click="alert('Edit admin details: ' + JSON.stringify(row))">Edit</button>
            </div>
          </div>
          <div class="grid-row add-admin-row" @click="showModal = true">
            <div class="cell add-admin-cell" colspan="3">
              <span class="add-admin-text">+ Add New Admins</span>
            </div>
          </div>
        </div>
      </div>
      <AddAdminModal v-if="showModal" :show="showModal" @close="showModal = false" @add-admin="handleAddAdmin" />
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { getAdmins, addAdmin } from '../api/axios';
  import AddAdminModal from '../components/AddAdminModal.vue';
  
  export default {
    name: 'SettingsTerminal',
    components: {
      AddAdminModal,
    },
    setup() {
      const rowData = ref([]);
      const defaultImage = '/assets/images/p.jpeg';
      const showModal = ref(false);
  
      const fetchAdmins = async () => {
        const adminId = localStorage.getItem('adminId'); // Get admin ID from localStorage
        if (!adminId) {
          console.error('No admin ID found');
          return;
        }
        try {
          const response = await getAdmins(adminId);
          rowData.value = response.data.data;
        } catch (error) {
          console.error('Failed to fetch admins:', error);
        }
      };
  
      const handleAddAdmin = async (newAdmin) => {
        const adminId = localStorage.getItem('adminId'); // Get admin ID from localStorage
        if (!adminId) {
          console.error('No admin ID found');
          return { status: false };
        }
        try {
          const response = await addAdmin(adminId, newAdmin);
          if (response && response.status) {
            fetchAdmins();
            return { status: true };
          } else {
            return { status: false };
          }
        } catch (error) {
          console.error('Error adding admin:', error);
          return { status: false };
        }
      };
  
      onMounted(() => {
        fetchAdmins();
      });
  
      return {
        rowData,
        defaultImage,
        showModal,
        handleAddAdmin,
      };
    },
  };
  </script>
  
  <style scoped>
  .settings-terminal {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .ag-theme-alpine {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .ag-grid-header, .ag-grid-body {
    display: flex;
    flex-direction: column;
  }
  
  .ag-grid-header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background: #f9f9f9;
    border-bottom: 1px solid #ccc;
  }
  
  .header-cell, .cell {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    text-align: center;
  }
  
  .grid-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  
  .admin-cell {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .profile-image-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .admin-info {
    text-align: left;
  }
  
  .admin-name {
    font-weight: bold;
  }
  
  .joined-date {
    font-size: 12px;
    color: #888;
  }
  
  .role {
    font-weight: bold;
  }
  
  .super-admin {
    color: #37b883;
  }
  
  .add-admin-row {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    grid-template-columns: 1fr;
  }
  
  .add-admin-text {
    font-size: 16px;
    font-weight: bold;
    color: #8a8a8a;
  }
  
  .add-admin-text:hover {
    text-decoration: underline;
  }
  </style>
  