<template>
  <div class="w-screen h-screen flex flex-col">
    <!-- top bar -->
    <div class="header h-[40px] bg-emerald-400 flex items-center shadow-sm px-[20px] w-full py-[10px] z-10 text-white">
      <div class="flex items-center space-x-2">
        <span>{{ currentDate }}</span>
        <span>|</span>
        <span>{{ currentTime }}</span>
      </div>
      <div class="flex items-center space-x-2 ml-auto">
        <span>{{ userName }}</span>
        <span>|</span>
        <button @click="handleLogout" class="focus:outline-none">Logout</button>
      </div>
    </div>

    <!-- main content -->
    <div class="main-content flex-1 flex justify-center items-center bg-white">
      <div class="content-box w-full h-full bg-white flex">
        <!-- side bar -->
        <div class="sidebar w-[300px] h-full bg-gray-200">
          <div class="menu h-[calc(100vh-50px)] bg-gray-250 py-[20px]">
            <div class="flex flex-col justify-between h-full">
              <div class="flex flex-col justify-between space-y-[10px] mt-[50px]">
                <div class="h-full">
                  <router-link to="/DashboardPage/HomeTerminal" class="menu-item inline-flex relative items-center py-[10px] px-[10px] w-full text-sm font-medium border-black hover:bg-white hover:text-emerald-400 transition duration-400 ease-in-out" :class="{ 'active-link': isActive('/DashboardPage/HomeTerminal') }">
                    <svg aria-hidden="true" class="mr-2 w-[25px] h-[25px] fill-current" fill="currentColor" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg">
                      <path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"/>
                    </svg>
                    Home
                  </router-link>
                  <router-link to="/DashboardPage/BuyersTerminal" class="menu-item inline-flex relative items-center py-[10px] px-[10px] w-full text-sm font-medium border-black hover:bg-white hover:text-emerald-400 transition duration-400 ease-in-out" :class="{ 'active-link': isActive('/DashboardPage/BuyersTerminal') }">
                    <svg aria-hidden="true" class="mr-2 w-[25px] h-[25px] fill-current" fill="currentColor" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg">
                      <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 256h64c44.2 0 80 35.8 80 80c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16c0-44.2 35.8-80 80-80zm-32-96a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zm256-32H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/>
                    </svg>
                    Buyers
                  </router-link>
                  <router-link to="/DashboardPage/SellersTerminal" class="menu-item inline-flex relative items-center py-[10px] px-[10px] w-full text-sm font-medium border-black hover:bg-white hover:text-emerald-400 transition duration-400 ease-in-out" :class="{ 'active-link': isActive('/DashboardPage/SellersTerminal') }">
                    <svg aria-hidden="true" class="mr-2 w-[25px] h-[25px] fill-current" fill="currentColor" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 80V229.5c0 17 6.7 33.3 18.7 45.3l176 176c25 25 65.5 25 90.5 0L418.7 317.3c25-25 25-65.5 0-90.5l-176-176c-12-12-28.3-18.7-45.3-18.7H48C21.5 32 0 53.5 0 80zm112 32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
                    </svg>
                    Sellers
                  </router-link>
                  <router-link to="/DashboardPage/CategoriesTerminal" class="menu-item inline-flex relative items-center py-[10px] px-[10px] w-full text-sm font-medium border-black hover:bg-white hover:text-emerald-400 transition duration-400 ease-in-out" :class="{ 'active-link': isActive('/DashboardPage/CategoriesTerminal') }">
                    <svg aria-hidden="true" class="mr-2 w-[25px] h-[25px] fill-current" fill="currentColor" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg">
                      <path d="M264.5 5.2c14.9-6.9 32.1-6.9 47 0l218.6 101c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 149.8C37.4 145.8 32 137.3 32 128s5.4-17.9 13.9-21.8L264.5 5.2zM476.9 209.6l53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 277.8C37.4 273.8 32 265.3 32 256s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0l152-70.2zm-152 198.2l152-70.2 53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 405.8C37.4 401.8 32 393.3 32 384s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0z"/>
                    </svg>
                    Categories
                  </router-link>
                  <router-link to="/DashboardPage/SystemInformation" class="menu-item inline-flex relative items-center py-[10px] px-[10px] w-full text-sm font-medium border-black hover:bg-white hover:text-emerald-400 transition duration-400 ease-in-out" :class="{ 'active-link': isActive('/DashboardPage/SystemInformation') }">
                    <svg aria-hidden="true" class="mr-2 w-[25px] h-[25px] fill-current" fill="currentColor" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
                    </svg>
                    System Information
                  </router-link>
                  <router-link to="/DashboardPage/SettingsTerminal" class="menu-item inline-flex relative items-center py-[10px] px-[10px] w-full text-sm font-medium border-black hover:bg-white hover:text-emerald-400 transition duration-400 ease-in-out" :class="{ 'active-link': isActive('/DashboardPage/SettingsTerminal') }">
                    <svg aria-hidden="true" class="mr-2 w-[25px] h-[25px] fill-current" fill="currentColor" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                      <path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/>
                    </svg>
                    Settings
                  </router-link>
                  <router-link to="/DashboardPage/AdminTerminal" class="menu-item inline-flex relative items-center py-[10px] px-[10px] w-full text-sm font-medium border-black hover:bg-white hover:text-emerald-400 transition duration-400 ease-in-out" :class="{ 'active-link': isActive('/DashboardPage/AdminTerminal') }">
                    <svg aria-hidden="true" class="mr-2 w-[25px] h-[25px] fill-current" fill="currentColor" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                      <path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/>
                    </svg>
                    Admins
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- main content -->
        <div class="content flex-1 bg-stone-50 p-4">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';

export default {
  name: 'DashboardPage',
  setup() {
    const router = useRouter();
    const route = useRoute();
    const userName = ref(localStorage.getItem('name') || 'Guest');
    const currentDate = ref('');
    const currentTime = ref('');

    const updateDateTime = () => {
      const now = new Date();
      currentDate.value = now.toLocaleDateString('en-US', { weekday: 'long', day: 'numeric', month: 'long' });
      currentTime.value = now.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    };

    onMounted(() => {
      updateDateTime();
      setInterval(updateDateTime, 60000);
    });

    const handleLogout = () => {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      localStorage.removeItem('adminId'); // Remove admin ID on logout
      router.push('/login');
    };

    const isActive = (path) => {
      return route.path === path;
    };

    return {
      userName,
      currentDate,
      currentTime,
      handleLogout,
      isActive,
    };
  },
};
</script>

<style scoped>
.active-link {
  background-color: white;
  color: #37B883;
}
.sidebar {
  height: 100vh;
}
.main-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.header {
  background-color: #37b883;
}
.content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e2e8f0;
  flex: 1;
  margin-top: 0;
}
.content-box {
  border: 1px solid #cbd5e0;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 95%;
  height: 100%;
}
</style>
